<template>
  <div
    style="
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    "
  >
    <notifications></notifications>
    <router-view class="router-view" :key="$route.fullPath"></router-view>
    <div class="loader" v-if="$store.state.isLoading">
      <!-- <img src="./assets/images/logo4_1.png" style="height: 125px; margin-left: auto; margin-right: auto; display: block; bottom: 10px;"/> -->
      <!-- <img src="./assets/images/giphy.gif" alt="" style="position: fixed; bottom: 0px;"/>
      <img src="./assets/images/giphy (1).gif" alt="" style="position: fixed; bottom: 0px; right: 0px;"/> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    this.$rtl.disableRTL();
  },
  methods: {
    disableRTL() {
      if (!this.$rtl.isRTL) {
        this.$rtl.disableRTL();
      }
    },
    toggleNavOpen() {
      let root = document.getElementsByTagName("html")[0];
      root.classList.toggle("nav-open");
    },
  },
  mounted() {
    this.$watch("$route", this.disableRTL, { immediate: true });
    this.$watch("$sidebar.showSidebar", this.toggleNavOpen);
  },
};
</script>

<style scoped>
.router-view {
  animation: fadein 1.5s;
  /* animation: circle-in-center 2s; */
}
.loader {
  /* Loader Div Class */
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  /* background-color:#eceaea; */
  background-color: #291c30;
  /* background-color: transparent; */
  /* background-image: url("./assets/images/loading-gif-orange-5.gif"); */
  /* background-image: url("./assets/images/loading-gif-transparent-15.gif"); */
  /* background-image: url("./assets/images/source (2).gif"); */
  background-image: url("./assets/images/spinning_sejawat.gif");
  background-size: 200px;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10000000;
  opacity: 0.8;
  filter: alpha(opacity=40);
}

@keyframes circle-in-center {
  from {
    clip-path: circle(0%);
  }
  to {
    clip-path: circle(125%);
  }
}

[transition-style="in:circle:center"] {
  animation: 2.5s cubic-bezier(.25, 1, .30, 1) circle-in-center both;
}

@keyframes fadein {
  from { opacity: 0}
  to   { opacity: 1}
}
</style>
