import Api from './api'

export default {
  data() {
    return {}
  },
  methods: {
    getCsrfCookie() {
      return Api.get('/csrf-cookie')
    },
    auth() {
      return Api.get('/user')
    },
    async registerUser(formData) {
      await this.getCsrfCookie()
      return Api.post('/register', formData)
    },
    async userLogin(formData) {
      await this.getCsrfCookie()
      return Api.post('/login', formData)
    },
    async userLogout() {
      await this.getCsrfCookie()
      return Api.post('/logout')
    },
    async apiGet(apiUrl, apiParams = {}) {
      await this.getCsrfCookie()
      return Api.get('/' + apiUrl, { params: apiParams })
    },
    async apiPost(apiUrl, formData) {
      await this.getCsrfCookie()
      return Api.post(('/' + apiUrl), formData)
    },
    async apiPostMultipart(apiUrl, formData) {
      await this.getCsrfCookie()
      return Api.post(('/' + apiUrl), formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },
    async apiPut(apiUrl, formData) {
      await this.getCsrfCookie()
      return Api.put(('/' + apiUrl), formData)
    },
    async apiPutMultipart(apiUrl, formData) {
      await this.getCsrfCookie()
      return Api.put(('/' + apiUrl), formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },
    async apiDelete(apiUrl) {
      await this.getCsrfCookie()
      return Api.delete('/' + apiUrl)
    }
  }
}