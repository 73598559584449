import axios from 'axios'
import { store } from '../store'

let Api = axios.create({
  // baseURL: 'http://localhost/sejawat-backend/public/api'
  baseURL: 'https://sejawat-online.com/sejawat-backend/public/api'
})

Api.defaults.withCredentials = true

// setup interceptors
Api.interceptors.request.use(function (config) {
  store.dispatch('setLoadingAction', true)
  return config
}, function (err) {
  store.dispatch('setLoadingAction', false)
  return Promise.reject(err)
})

Api.interceptors.response.use(function (response) {
  store.dispatch('setLoadingAction', false)
  return response
}, function (err) {
  store.dispatch('setLoadingAction', false)
  return Promise.reject(err)
})

export default Api