import LoginLayout from "@/layout/LoginLayout"
import RegisterLayout from "@/layout/RegisterLayout"
import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue"

// GeneralViews
import NotFound from "@/pages/NotFoundPageNew.vue"

// import vuex
import store from "../store"

// Pages for admin
const Dashboard = () => import(/* webpackChunkName: "admin" */"@/pages/Dashboard.vue")
const PricingSystem = () => import(/* webpackChunkName: "admin" */"@/pages/PricingSystem.vue")
const SubjectBundling = () => import(/* webpackChunkName: "admin" */"@/pages/SubjectBundling.vue")
const MasterPeriod = () => import(/* webpackChunkName: "admin" */"@/pages/Master/MasterPeriod.vue")
const ManagePeriod = () => import(/* webpackChunkName: "admin" */"@/pages/ManagePeriod.vue")
const ManagePeriodUserList = () => import(/* webpackChunkName: "admin" */"@/pages/ManagePeriodUserList.vue")
const MasterQuestion = () => import(/* webpackChunkName: "admin" */"@/pages/MasterQuestions.vue")
const ManageQuestion = () => import(/* webpackChunkName: "admin" */"@/pages/ManageQuestions.vue")
const ManageQuestionForm = () => import(/* webpackChunkName: "admin" */"@/pages/ManageQuestionsForm.vue")
const MasterExam = () => import(/* webpackChunkName: "admin" */"@/pages/Master/MasterExam.vue")
const ManageExam = () => import(/* webpackChunkName: "admin" */"@/pages/ManageExam.vue")
const UnblockUserExam = () => import(/* webpackChunkName: "admin" */"@/pages/Tryouts/admin/UnblockUserExam.vue")
const CompreModule = () => import(/* webpackChunkName: "admin" */"@/pages/Compre/Temp.vue")
const ModulesList = () => import(/* webpackChunkName: "admin" */"@/pages/ModulesList.vue")
const ResetUserPassword = () => import(/* webpackChunkName: "admin" */"@/pages/ResetUserPassword.vue")

// Reporting pages for admin
const UserExamScoreReport = () => import(/* webpackChunkName: "admin" */"@/pages/Reports/UserExaminationScoreReport.vue")

// Pages for non admin
const EditProfile = () => import(/* webpackChunkName: "common" */ "@/pages/EditProfile.vue")
const Profile = () => import(/* webpackChunkName: "common" */ "@/pages/Profile.vue")
const TutoringRegistration = () => import(/* webpackChunkName: "common" */ "@/pages/TutoringRegistration.vue")
const TutoringRegistrationForm = () => import(/* webpackChunkName: "common" */ "@/pages/TutoringRegistrationForm.vue")
const OpenTryouts = () => import(/* webpackChunkName: "common" */ "@/pages/Tryouts/TryoutsList.vue")
const TryoutPaper = () => import(/* webpackChunkName: "common" */ "@/pages/Tryouts/TryoutPaper.vue")
const TryoutsHistory = () => import(/* webpackChunkName: "common" */ "@/pages/Tryouts/TryoutsHistory.vue")

// unused pages
const Notifications = () => import(/* webpackChunkName: "common" */"@/pages/Notifications.vue")
const Icons = () => import(/* webpackChunkName: "common" */ "@/pages/Icons.vue")
const Maps = () => import(/* webpackChunkName: "common" */ "@/pages/Maps.vue")
const Typography = () => import(/* webpackChunkName: "common" */ "@/pages/Typography.vue")
const TableList = () => import(/* webpackChunkName: "common" */ "@/pages/TableList.vue")

const routes = [
  {
    path: "/login",
    component: LoginLayout
  },
  {
    path: "/register",
    component: RegisterLayout
  },
  {
    path: "/tryout-paper/:user_exam_id/:user_id/:exam_id",
    component: TryoutPaper
  },
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard
      },
      {
        path: "pricing-system",
        name: "Pricing System",
        component: PricingSystem
      },
      {
        path: "subject-bundling",
        name: "Subject Bundling",
        component: SubjectBundling
      },
      {
        path: "master-period",
        name: "master period",
        component: MasterPeriod
      },
      {
        path: "manage-period",
        name: "manage period",
        component: ManagePeriod
      },
      {
        path: "manage-period/:id",
        name: "period registration",
        component: ManagePeriodUserList
      },
      {
        path: "master-questions",
        name: "Master Questions",
        component: MasterQuestion
      },
      {
        path: "manage-questions",
        name: "Manage Questions",
        component: ManageQuestion
      },
      {
        path: "manage-questions/:id",
        name: "Manage Questions",
        component: ManageQuestionForm
      },
      {
        path: "master-exam",
        name: "Master Exam",
        component: MasterExam
      },
      {
        path: "manage-exam",
        name: "Manage Exam",
        component: ManageExam
      },
      {
        path: "unblock-user-exam",
        name: "Unblock Exam",
        component: UnblockUserExam
      },
      {
        path: "compre-module",
        name: "Compre Module",
        component: CompreModule
      },
      {
        path: "modules-list",
        name: "Modules List",
        component: ModulesList
      },
      {
        path: "reset-user-password",
        name: "Reset Password",
        component: ResetUserPassword
      },
      {
        path: "user-exam-score-report",
        name: "Examination Score",
        component: UserExamScoreReport
      },
      {
        path: "edit-profile",
        name: "edit profile",
        component: EditProfile
      },
      {
        path: "tutoring-registration",
        name: "tutoring periods",
        component: TutoringRegistration
      },
      {
        path: "tutoring-registration/:id",
        name: "registration form",
        component: TutoringRegistrationForm
      },
      {
        path: "open-tryouts",
        name: "Open Tryouts",
        component: OpenTryouts
      },
      {
        path: "tryouts-history",
        name: "Tryouts History",
        component: TryoutsHistory
      },
      {
        path: "profile",
        name: "profile",
        component: Profile
      },
      {
        path: "notifications",
        name: "notifications",
        component: Notifications
      },
      {
        path: "icons",
        name: "icons",
        component: Icons
      },
      {
        path: "maps",
        name: "maps",
        component: Maps
      },
      {
        path: "typography",
        name: "typography",
        component: Typography
      },
      {
        path: "table-list",
        name: "table list",
        component: TableList
      }
    ]
  },
  { path: "*", component: NotFound },
]

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes
