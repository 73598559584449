<template>
  <footer class="footer" style="
      padding: 10px 0px 10px 0px;
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    ">
    <div class="copyright text-right">
      SELANGKAH LAGI MENJADI SEJAWAT
      <br />
      © {{ year }} Sejawat LC. All rights reserved.
    </div>
    <div class="copyright mr-2">
      <img src="img/combined_logo.png" width="300px" style="max-height: 50px;" />
    </div>
    <!-- <div style="background-color: transparent; width: 300px; position: absolute; left: 20em; padding-bottom: 10px;">
      <img src="img/combined_logo.png" width="300px"  style="max-height: 50px;"/>
    </div> -->
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear(),
    }
  },
}
</script>
<style></style>
