import VueRouter from "vue-router";
import routes from "./routes";
import {store} from "../store";
import apiMixin from "../mixins/apiMixin";

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkExactActiveClass: "active",
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  }
});

router.beforeEach((to, from, next) => {

  // create routing logic
  // except incoming path to login and register
  if (to.path != "/login" && to.path != "/register") {
    
    // non login/register route
    // do user authentication
    apiMixin.methods.auth()
    .then(response => {

      // auth okay, proceedto next route
      next();
    })
    .catch(error => {

      // auth not okay, redirect to login route
      // and clear store with its persistent data
      store.dispatch('resetUserStoreValues');
      localStorage.removeItem('vuex');
      next('/login');
    }); 

  } else {

    // login/register route detected
    // route is login and register, allow without auth
    next();
  }

});

export default router;
