<template>
  <div 
    class="landing-card" 
    style="
      box-shadow: 1px 3px 23px 3px rgba(0,0,0,0.50);
      -webkit-box-shadow: 1px 3px 23px 3px rgba(0,0,0,0.50);
      -moz-box-shadow: 1px 3px 23px 3px rgba(0,0,0,0.50);
    "
  >
    <card type="user">
      <div class="author">
        <div class="block block-one"></div>
        <div class="block block-two"></div>
        <div class="block block-three"></div>
        <!-- <img class="avatar" src="img/logo3_1.png" alt="..."> -->
        <img  class="mt-5 mb-5" src="img/combined_logo.png" width="350px"  style="max-height: 60px;"/>
      </div>

      <center>
        <h3>Sejawat - User Login</h3>
      </center>

      <base-input 
        v-model="form.email"
        type="text" 
        addon-left-icon="tim-icons icon-single-02"
        placeholder="Enter your registered email"
        v-on:keyup.enter="doLogin"
      />

      <base-input 
        v-model="form.password"
        type="Password"
        addon-left-icon="tim-icons icon-key-25"
        placeholder="Make sure the password is right"
        v-on:keyup.enter="doLogin"
      />

      <div class="row text-center mt-4">
        <div class="col-md-12">
          Not registered yet? <router-link :to="'register'">Register Here.</router-link>
        </div>
      </div>

      <div slot="footer" class="button-container">
        <base-button
          block 
          type="primary" 
          @click="doLogin"
        >
          <i class="tim-icons icon-tap-02"></i> 
          Login
        </base-button>
      </div>
    </card>
  </div>
</template>

<script>
  import BaseInput from '../components/Inputs/BaseInput.vue';

  export default {
    name: "loginLayout", 
    data () {
      return {
        form: {
          email: null, 
          password: null,
        }
      };
    },
    created () {

      window.aku = this;
      
      // check auth
      this.auth()
      .then(() => {
        // user authenticated, redirect to dashboard
        this.$router.push('/dashboard');
      })
      .catch(() => {
        this.$store.dispatch('resetUserStoreValues');
        localStorage.removeItem('vuex');
      })
    },
    methods: {
      doLogin(evt) {

        // validate the form first
        let isValid = true;
        if (this.form.email === null || this.form.email == "" || this.form.email === undefined) {

          // flip the validation flag
          isValid = false;

          this.$notify({
            title: "Login Failed", 
            message: "Email field cannot be empty.", 
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "right",
            verticalAlign: "bottom",
            type: "info",
            timeout: 3000
          });  
        }

        if (this.form.password === null || this.form.password == "" || this.form.password === undefined) {

          // flip the validation flag
          isValid = false;

          this.$notify({
            title: "Login Failed", 
            message: "Password field cannot be empty.", 
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "right",
            verticalAlign: "bottom",
            type: "info",
            timeout: 3000
          });  
        }

        if (isValid) {

          // validation successful, 
          // proceed with next process, 
          // send login data to backend and process the response

          this.userLogin(this.form)
          .then (response => {

            // check response status (must be code 200)
            if (response.status == 200) {

              // login successful, set user data store
              this.$store.dispatch('setUserAction', {
                userId: response.data.id, 
                userName: response.data.name, 
                userEmail: response.data.email, 
                userAuth: true
              });

              // get user profile data from API
              this.apiGet('userProfile/' + response.data.id)
              .then(response => {

                if (response.data) {

                  // user profile exists
                  // logged in user is not admin
                  // set profile to vuex store
                  this.$store.dispatch('setUserProfileValue', {
                    profileId: response.data.id, 
                    address: response.data.address, 
                    phoneNumber: response.data.phone_number, 
                    gender: response.data.gender, 
                    originUniversity: response.data.origin_university, 
                    studentGeneration: response.data.student_generation, 
                    lineId: response.data.line_id
                  });
                } else {

                  // user does not have profile data
                  // logged in user is admin
                  // no need to set profile data
                  // but set admin flag to true
                  this.$store.dispatch('setIsAdminValue', true);
                }

                // redirect to dashboard for both admin and non admins
                this.$router.push('/dashboard');
              });
            }
          })
          .catch (error => {

            this.$notify({
              title: "Login Authorization Failed", 
              message: error.response.data.message, 
              icon: "tim-icons icon-simple-remove",
              horizontalAlign: "right",
              verticalAlign: "bottom",
              type: "info",
              timeout: 3000
            });
          });
        }
      }
    }
  }
</script>

<style scoped>
  body {
    background: linear-gradient(70deg,#5050ba,#2e2e75);
  }
  .landing-card {
    background-color: #27293d; 
    width: 35%;
    height: 100%;
    margin: 0px auto;
    border-radius: 5px;
    color: #777885;
  }
  @media only screen and (max-width: 768px) {
    .landing-card {
      background-color: #27293d; 
      width: 100%;
      height: 100%;
      margin: 0px auto;
      border-radius: 5px;
      color: #777885;
    }
  }
</style>