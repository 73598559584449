<template>
  <div class="datatable-sejawat-container">
    <div class="row">
      <div class="col-12">
        <h4>
          {{ title }}
        </h4>
        <hr />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table class="table table-bordered table-sm">
          <thead>
            <tr>
              <th v-for="field in fields" :key="field">
                {{ field.key }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="items.length <= 0">
              <td class="text-center align-middle" :colspan="fields.length">
                No Rows To Be Displayed
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * CUSTOMIZED DATATABLE
 * CREATED BY RICHARD @ 19-02-2023
 */
export default {
  name: "datatable-sejawat",
  props: {
    title: {
      type: String,
      default: "Default Title",
    },
    emptyText: {
      type: String,
      default: "No Rows To Be Displayed",
    },
    fields: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.initComponent();
  },
  data() {
    return {};
  },
  methods: {
    async initComponent() {
      // do something when innitialized
    },
  },
};
</script>

<style #scoped>
.datatable-sejawat-container {
  background-color: #424242;
  border-radius: 10px;
  padding: 15px 15px 15px 15px;
}
hr {
  border: 0.1px solid white;
}
</style>