export default {
  data() {
    return {
      fe_path: 'https://sejawat-backend.com',
      api_path: 'https://sejawat-backend.com/sejawat-backend/public/api',
      backend_root: 'https://sejawat-online.com/sejawat-backend/public'
      // fe_path: 'localhost:8080',
      // api_path: 'http://localhost/sejawat-backend/public/api',
      // backend_root: 'http://localhost/sejawat-backend/public'
    }
  },
  methods: {
    formatDate(d) {
      var date = new Date(d)

      if (isNaN(date.getTime())) {
        return d
      } else {
        var month = new Array()
        month[0] = "Jan"
        month[1] = "Feb"
        month[2] = "Mar"
        month[3] = "Apr"
        month[4] = "May"
        month[5] = "Jun"
        month[6] = "Jul"
        month[7] = "Aug"
        month[8] = "Sept"
        month[9] = "Oct"
        month[10] = "Nov"
        month[11] = "Dec"

        var day = date.getDate()

        if (day < 10) {
          day = "0" + day
        }

        return day + "-" + month[date.getMonth()] + "-" + date.getFullYear()
      }
    },
    formatDateWithTime(d) {
      let date_ob = new Date(d)

      // adjust 0 before single digit date
      let date = ("0" + date_ob.getDate()).slice(-2)

      // current month
      let month = ("0" + (date_ob.getMonth() + 1)).slice(-2)

      // current year
      let year = date_ob.getFullYear()

      // current hours
      let hours = date_ob.getHours()

      // current minutes
      let minutes = date_ob.getMinutes()

      // current seconds
      let seconds = date_ob.getSeconds()

      // prints date & time in YYYY-MM-DD HH:MM:SS format
      var mString = new Array()
      mString[0] = "Jan"
      mString[1] = "Feb"
      mString[2] = "Mar"
      mString[3] = "Apr"
      mString[4] = "May"
      mString[5] = "Jun"
      mString[6] = "Jul"
      mString[7] = "Aug"
      mString[8] = "Sept"
      mString[9] = "Oct"
      mString[10] = "Nov"
      mString[11] = "Dec"
      return (date + "-" + mString[date_ob.getMonth()] + "-" + year + " " + hours + ":" + minutes + ":" + seconds)
    },
    showErrorWithCustomName(formName) {
      this.$notify({
        title: "Input Invalid",
        message: formName + " field cannot be empty.",
        icon: "tim-icons icon-alert-circle-exc",
        horizontalAlign: "right",
        verticalAlign: "bottom",
        type: "info",
        timeout: 3000,
      })
    },
    showErrorWithCustomTitleAndContent(titleContent, messageContent) {
      this.$notify({
        title: titleContent,
        message: messageContent,
        icon: "tim-icons icon-alert-circle-exc",
        horizontalAlign: "right",
        verticalAlign: "bottom",
        type: "info",
        timeout: 3000,
      })
    },
    showSuccessMessage(message) {
      this.$swal({
        title: "Success",
        text: message,
        icon: "success",
      })
    },
    showApiErrorMessage(message) {
      this.$swal({
        title: "Action Failed",
        text: message,
        icon: "error",
      })
    },
    timePad(d) {
      return (d < 10) ? '0' + d.toString() : d.toString()
    }
  }
}