/*
 =========================================================
 * Vue Black Dashboard - v1.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/black-dashboard
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from 'vue-router-prefetch';
import Vuex from "vuex";
import App from "./App";

// TIP: change to import router from "./router/starterRouter"; to start with a clean layout
import router from "./router/index";

// import vue axios
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueFileAgent from 'vue-file-agent';
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';

// import vue excel exporter
import JsonExcel from "vue-json-excel";

// import black dashboard plugin
import BlackDashboard from "./plugins/blackDashboard";

// import sweetalert2 plugin
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

// import custom vue material components
import VueMaterial from 'vue-material';

// import vue material css(s)
import 'vue-material/dist/vue-material.min.css';
import 'vue-material/dist/theme/default-dark.css';

// import custom scrollbar plugin
import VueCustomScrollbar from 'vue-custom-scrollbar'
import "vue-custom-scrollbar/dist/vueScrollbar.css"

import i18n from "./i18n";
import './registerServiceWorker';

// import vuex store
import { store } from "./store";

// import mixin
import apiMixin from "./mixins/apiMixin";
import helperMixin from "./mixins/helperMixin";

Vue.use(BlackDashboard);
Vue.use(VueFileAgent);
Vue.use(VueSweetalert2);
Vue.use(VueMaterial);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.use(Vuex);
Vue.use(VueAxios, axios);
Vue.use(VueCustomScrollbar);

Vue.component("downloadExcel", JsonExcel);

// import custom datatable by Richard @ 19-02-2023
import DatatableSejawat from './components/Custom/Datatable/DatatableSejawat';
Vue.component("DatatableSejawat", DatatableSejawat);

// use mixin
Vue.mixin(apiMixin);
Vue.mixin(helperMixin);

/* eslint-disable no-new */
new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount("#app");