<template>
  <div 
    class="landing-card"
    style="
      box-shadow: 1px 3px 23px 3px rgba(0,0,0,0.50);
      -webkit-box-shadow: 1px 3px 23px 3px rgba(0,0,0,0.50);
      -moz-box-shadow: 1px 3px 23px 3px rgba(0,0,0,0.50);
    "
  >
    <transition>
      <card type="user">

        <div class="author">
          <div class="block block-one"></div>
          <div class="block block-two"></div>
          <div class="block block-three"></div>
          <!-- <img class="avatar" src="img/logo3_1.png" alt="..."> -->
          <img  class="mt-5 mb-5" src="img/combined_logo.png" width="350px"  style="max-height: 60px;"/>
        </div>

        <center>
          <h3>Sejawat - Registration</h3>
        </center>

        <base-input 
          v-model="form.name"
          type="text" 
          addon-left-icon="tim-icons icon-single-02"
          placeholder="Enter your full name"
          v-on:keyup.enter="doRegister"
        />

        <base-input 
          v-model="form.email"
          type="email"
          addon-left-icon="tim-icons icon-email-85"
          placeholder="Enter your valid email address"
          v-on:keyup.enter="doRegister"
        />

        <base-input 
          v-model="form.password"
          type="Password"
          addon-left-icon="tim-icons icon-key-25"
          placeholder="Type your password here"
          v-on:keyup.enter="doRegister"
        />

        <base-input 
          v-model="form.password_confirmation"
          type="Password"
          addon-left-icon="tim-icons icon-lock-circle"
          placeholder="Re-type your password"
          v-on:keyup.enter="doRegister"
        />

        <span>
          <small>
            Password Format: 7 to 15 characters which contain only characters, numeric digits, underscore and first character must be a letter]
          </small>
        </span>

        <div slot="footer" class="button-container">
          <base-button
            block 
            type="warning" 
            @click.prevent="doRegister"
          >
            <i class="tim-icons icon-email-85"></i> 
            Register
          </base-button>
          <base-button
            block 
            type="danger" 
            @click="function (evt) {
              $router.push('login');
            }"
          >
            <i class="tim-icons icon-double-left"></i> 
            Back To Login Page
          </base-button>
        </div>
      </card>
    </transition>
  </div>
</template>

<script>
  import BaseInput from '../components/Inputs/BaseInput.vue';

  export default {
    name: "loginLayout", 
    data () {
      return {
        form: {
          name: null, 
          email: null, 
          password: null,
          password_confirmation: null, 
        },
        reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/, 
      };
    },
    created () {
      window.aku = this;

      // check auth
      this.auth()
      .then(() => {
        // user authenticated, redirect to dashboard
        this.$router.push('/dashboard');
      })
    },
    methods: {
      doRegister(evt) {

        // validate the form first
        let isValid = true;
        if (this.form.name === null || this.form.name == "" || this.form.name === undefined) {

          // flip the validation flag
          isValid = false;

          this.$notify({
            title: "Registration Failed", 
            message: "Full name field cannot be empty.", 
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "right",
            verticalAlign: "bottom",
            type: "info",
            timeout: 3000
          });  
        }

        if (this.form.password === null || this.form.password == "" || this.form.password === undefined) {

          // flip the validation flag
          isValid = false;

          this.$notify({
            title: "Registration Failed", 
            message: "Password field cannot be empty.", 
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "right",
            verticalAlign: "bottom",
            type: "info",
            timeout: 3000
          });  
        } else {
          // password field is not empty but there is another validation
          // do special validation for password content because a lot of users still using stupid ass password format
          let passwordFormat = /^[A-Za-z]\w{7,14}$/;
          if (!this.form.password.match(passwordFormat)) {

            // flip the validation flag
            isValid = false;

            this.$notify({
              title: "Registration Failed", 
              message: "Wrong password format.", 
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "right",
              verticalAlign: "bottom",
              type: "info",
              timeout: 3000
            });  
          }
        }

        if (this.form.password_confirmation === null || this.form.password_confirmation == "" || this.form.password_confirmation === undefined) {

          // flip the validation flag
          isValid = false;

          this.$notify({
            title: "Registration Failed", 
            message: "Password confirmation field cannot be empty.", 
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "right",
            verticalAlign: "bottom",
            type: "info",
            timeout: 3000
          });  
        }

        if (this.form.email === null || this.form.email == "" || this.form.email === undefined) {

          // flip the validation flag
          isValid = false;

          this.$notify({
            title: "Registration Failed", 
            message: "Email field cannot be empty.", 
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "right",
            verticalAlign: "bottom",
            type: "info",
            timeout: 3000
          });  
        }

        if (this.form.password_confirmation != this.form.password) {

          // flip the validation flag
          isValid = false;

          this.$notify({
            title: "Registration Failed", 
            message: "Password confirmation doesn't match.", 
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "right",
            verticalAlign: "bottom",
            type: "info",
            timeout: 3000
          });  
        }

        if (!this.reg.test(this.form.email)) {

          // flip the validation flag
          isValid = false;

          this.$notify({
            title: "Registration Failed", 
            message: "Please enter a valid email address.", 
            icon: "tim-icons icon-alert-circle-exc",
            horizontalAlign: "right",
            verticalAlign: "bottom",
            type: "info",
            timeout: 3000
          });  
        }

        if (isValid) {

          // validation successful, 
          // send registration request to API
          this.registerUser(this.form)
          .then (() => {
            
            // give registration success message
            this.$notify({
              title: "Registration Success", 
              message: "Registration submitted successfully. Check your email for verification.", 
              icon: "tim-icons icon-alert-circle-exc",
              horizontalAlign: "right",
              verticalAlign: "bottom",
              type: "info",
              timeout: 5000
            });
            
            // redirect user to home page
            this.$router.push('/login');
          })
          .catch(error => {
            
            // give registration failure message
            this.$notify({
              title: "Registration Failed", 
              message: "Cannot register this identity. Possibilities:<br> - email address already taken,<br>- failed to send email verification,<br>- server failure,<br>- no connection.", 
              icon: "tim-icons icon-simple-remove",
              horizontalAlign: "right",
              verticalAlign: "bottom",
              type: "info",
              timeout: 5000
            });
          })
        }
      }
    }
  }
</script>

<style scoped>
  .landing-card {
    background-color: #27293d; 
    width: 35%;
    height: 100%;
    margin: 0px auto;
    border-radius: 5px;
    color: #777885;
  }
  @media only screen and (max-width: 768px) {
    .landing-card {
      background-color: #27293d; 
      width: 100%;
      height: 100%;
      margin: 0px auto;
      border-radius: 5px;
      color: #777885;
    }
  }
</style>