<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links" v-if="!$store.state.isAdmin">
        <sidebar-link
          to="/dashboard"
          :name="$t('sidebar.dashboard')"
          icon="tim-icons icon-chart-pie-36"
        />
        <sidebar-link
          to="/edit-profile"
          :name="$t('sidebar.editProfile')"
          icon="tim-icons icon-pencil"
        />
        <md-divider
          class="mt-2 ml-3 mr-3"
          style="background-color: #a0dac0"
        ></md-divider>
        <sidebar-link
          to="/tutoring-registration"
          :name="$t('sidebar.tutoringRegistration')"
          icon="tim-icons icon-paper"
        />
        <sidebar-link
          to="/open-tryouts"
          :name="$t('sidebar.openTryouts')"
          icon="tim-icons icon-laptop"
        />
        <sidebar-link
          to="/tryouts-history"
          :name="$t('sidebar.tryoutsHistory')"
          icon="tim-icons icon-trophy"
        />
      </template>

      <template slot="links" v-else id="style-2">
        <sidebar-link
          to="/dashboard"
          :name="$t('sidebar.dashboard')"
          icon="tim-icons icon-chart-pie-36"
        />
        <sidebar-link
          to="/edit-profile"
          :name="$t('sidebar.editProfile')"
          icon="tim-icons icon-pencil"
        />
        <md-divider
          class="mt-2 ml-3 mr-3"
          style="background-color: #a0dac0"
        ></md-divider>
        <sidebar-link
          to="/pricing-system"
          :name="$t('sidebar.pricingSystem')"
          icon="tim-icons icon-coins"
        />
        <sidebar-link
          to="/subject-bundling"
          :name="$t('sidebar.subjectBundling')"
          icon="tim-icons icon-molecule-40"
        />
        <!-- <md-divider class="mt-2 ml-3 mr-3" style="background-color: #a0dac0;"></md-divider> -->

        <sidebar-link
          to="/master-period"
          :name="$t('sidebar.masterPeriod')"
          icon="tim-icons icon-calendar-60"
        />
        <sidebar-link
          to="/manage-period"
          :name="$t('sidebar.managePeriod')"
          icon="tim-icons icon-settings"
        />
        <md-divider
          class="mt-2 ml-3 mr-3"
          style="background-color: #a0dac0"
        ></md-divider>
        <sidebar-link
          to="/master-questions"
          :name="$t('sidebar.masterQuestions')"
          icon="tim-icons icon-chat-33"
        />
        <sidebar-link
          to="/manage-questions"
          :name="$t('sidebar.manageQuestions')"
          icon="tim-icons icon-settings"
        />
        <md-divider
          class="mt-2 ml-3 mr-3"
          style="background-color: #a0dac0"
        ></md-divider>
        <sidebar-link
          to="/master-exam"
          :name="$t('sidebar.masterExam')"
          icon="tim-icons icon-book-bookmark"
        />
        <sidebar-link
          to="/manage-exam"
          :name="$t('sidebar.manageExam')"
          icon="tim-icons icon-settings"
        />
        <sidebar-link
          to="/unblock-user-exam"
          :name="$t('sidebar.unblockUserExam')"
          icon="tim-icons icon-link-72"
        />
        <md-divider
          class="mt-2 ml-3 mr-3"
          style="background-color: #a0dac0"
        ></md-divider>
        <!-- <sidebar-link
          to="/compre-module"
          :name="$t('sidebar.compreModule')"
          icon="tim-icons icon-bulb-63"
        /> -->

        <sidebar-link
          to="/modules-list"
          :name="$t('sidebar.modulesList')"
          icon="tim-icons icon-components"
        />
        <md-divider
          class="mt-2 ml-3 mr-3"
          style="background-color: #a0dac0"
        ></md-divider>
        <sidebar-link
          to="/reset-user-password"
          :name="$t('sidebar.resetUserPassword')"
          icon="tim-icons icon-lock-circle"
        />
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"> </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style>
</style>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
  },
  created() {},
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
