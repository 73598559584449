import Vue from "vue";
import Vuex from "vuex";

// import vuex persisted state
import VuexPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export const store = new Vuex.Store({

  plugins: [VuexPersistedState()], 
  state: {
    userData: {
      userId: null,
      userName: null, 
      userEmail: null, 
      userAuth: false
    },
    userProfile: {
      profileId: null, 
      address: null, 
      phoneNumber: null,
      gender: null, 
      originUniversity: null, 
      studentGeneration: null, 
      lineId: null
    },
    isAdmin: false, 
    isLoading: false, 
    pricingSystemList: [
      "Open Class/sesi (Rp. 150.000,-)", 
      "Open Class Bundle 22 classes + Buku Latihan UKMP2DG (Rp. 2.800.000,-)",
      "Private 1 pax (Rp. 1.000.000,-/orang/sesi)",
      "Private 2-3 pax (Rp. 600.000,-/orang/sesi)", 
      "Private 4-5 pax (Rp. 400.000,-/orang/sesi)", 
      "Private 6-8 pax (Rp. 350.000,-/orang/sesi)", 
      "Private 9-10 pax (Rp. 300.000,-/orang/sesi)", 
      "Private >10pax (Rp. 250.000,-/orang/sesi)"
    ]
  }, 
  actions: {
    setUserAction({commit}, payload) {
      commit('setUser', payload);
    },
    setUserProfileValue({commit}, payload) {
      commit('setUserProfile', payload);
    },
    setLoadingAction({commit}, payload) {
      commit('setLoading', payload);
    }, 
    setIsAdminValue({commit}, payload) {
      commit('setIsAdmin', payload);
    },
    resetUserStoreValues({commit}) {
      commit('resetUserStore');
    },
    setPricingSystemListData({commit}, payload) {
      commit('setPricingSystemList');
    }
  }, 
  mutations: {
    setUser (state, payload) {
      state.userData.userId = payload.userId;
      state.userData.userName = payload.userName;
      state.userData.userEmail = payload.userEmail;
      state.userData.userAuth = payload.userAuth;
    }, 
    setUserProfile (state, payload) {
      state.userProfile.profileId = payload.profileId;
      state.userProfile.address = payload.address;
      state.userProfile.phoneNumber = payload.phoneNumber;
      state.userProfile.gender = payload.gender;
      state.userProfile.originUniversity = payload.originUniversity;
      state.userProfile.studentGeneration = payload.studentGeneration;
      state.userProfile.lineId = payload.lineId;
    },
    setLoading (state, payload) {
      state.isLoading = payload;
    }, 
    setIsAdmin (state, payload) {
      state.isAdmin = payload;
    }, 
    resetUserStore (state) {
      state.userData.userId = null;
      state.userData.userName = null;
      state.userData.userEmail = null;
      state.userData.userAuth = false;
      state.userProfile.profileId = null;
      state.userProfile.address = null;
      state.userProfile.phoneNumber = null;
      state.userProfile.gender = null;
      state.userProfile.originUniversity = null;
      state.userProfile.studentGeneration = null;
      state.userProfile.lineId = null;
      state.isAdmin = false;
    }, 
    setPricingSystemList (state, payload) {
      state.pricingSystemList = payload;
    }
  }, 
  getters: {
    getState (state) {
      return state;
    }
  }
});